import React, { PureComponent } from "react"
import { graphql } from "gatsby"
import * as Modules from "../components/modules"
import * as Organisms from "../components/organisms"
import { css } from "@emotion/react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import * as Libs from "libs"
export default class Category extends PureComponent {
  contCss = () => css`
    ${Libs.Common.Func.getPcSpVwValue("margin-top", 350, 275, true)};
  `

  constructor(props) {
    super(props)
    this.itemNum = 0
    this.counter = 0
  }
  componentDidMount() {
    Libs.Common.Config.excuteTransition()
  }
  shouldComponentUpdate(nextProps, nextState) {
    return false
  }
  render() {
    const { data, pageContext } = this.props
    const category = data.contentfulCommonCategory
    const currentSlug = pageContext.slug
    const items = category["content___" + currentSlug]
    this.itemNum = items ? items.length : 0

    const getCategoryList = slug => {
      if (!items) return ""
      if (slug === "works") {
        return (
          <Modules.WorksCategory
            type={"category"}
            items={items}
            slug={currentSlug}
          />
        )
      } else if (slug === "journal") {
        return (
          <Organisms.CategoryPage
            type={"category"}
            items={items}
            slug={currentSlug}
          />
        )
      } else {
        const variants = data.allShopifyProductVariant.edges

        return (
          <Modules.ProductsCategory
            lang={category.node_locale}
            type={"category"}
            items={items}
            variants={variants}
            slug={currentSlug}
          />
        )
      }
    }
    let schemaOrgJSONLDList = []

    const setContentSchemaOrgJSONLD = slug => {
      const lang = category.node_locale

      if (slug === "works") {
        return
      } else if (slug === "journal") {
        return
      } else {
        const variants = data.allShopifyProductVariant.edges

        const priceMap = new Map()
        const avalableMap = new Map()
        variants.forEach((variant, index) => {
          const id = variant.node.id.replace("Shopify__ProductVariant__", "")
          const map = new Map()
          let avalable = variant.node.product.availableForSale
          const vendor = variant.node.product.vendor

          if (lang === "en") {
            if (vendor === "HowltCoffee" || vendor === "openlogi") {
              avalable = false
            }
          }
          variant.node.presentmentPrices.edges.forEach(
            (presentmentPrice, index) => {
              map.set(
                presentmentPrice.node.price.currencyCode,
                presentmentPrice.node.price.amount
              )
            }
          )

          avalableMap.set(id, avalable)
          priceMap.set(id, map)
        })

        if (items)
          items.map((item, i) => {
            if (item.name === "empty") return
            const currencyCode = lang === "en" ? "USD" : "JPY"
            const priceMapObj = priceMap.get(item.shopifyId)
            if (!priceMapObj) {
            } else {
              //console.log("item -- 2", item)
            }
            const price = priceMapObj
              ? Libs.Common.StoreFunc.changeFormatToPrice(
                  priceMap.get(item.shopifyId).get(currencyCode),
                  currencyCode
                )
              : ""
            const isAvailable = avalableMap.get(item.shopifyId)

            schemaOrgJSONLDList.push({
              "@context": "https://schema.org/",
              "@type": "Product",
              image: `https:${item.thumbnail.fluid.src}`,
              url: `https://www.howlt.com/${category.node_locale}/shop/${item.slug}`,
              name: item.name,
              description: item.description
                ? documentToReactComponents(JSON.parse(item.description.raw))[0]
                    .props.children[0]
                : "",
              brand: {
                "@type": "Organization",
                name: "Howlt",
              },
              offers: {
                "@type": "Offer",
                url: `https://www.howlt.com/${category.node_locale}/shop/${item.slug}`,
                priceCurrency: currencyCode,
                price:
                  lang === "en"
                    ? parseFloat(price.replace("$", ""))
                    : parseInt(price.replace("¥", "").replace(/,/g, "")),
                availability: `http://schema.org/${
                  !isAvailable ? "SoldOut" : "InStock"
                }`,
              },
            })
          })
      }
    }

    schemaOrgJSONLDList.push(
      Libs.Common.SEO.getBreadcrumbSchemaOrgJSONLD([
        { name: "Home", url: `/${category.node_locale}/` },
        {
          name: category.name,
          url: `/${category.node_locale}/${category.slug}/`,
        },
      ])
    )

    setContentSchemaOrgJSONLD(currentSlug)

    return (
      <>
        <Libs.Common.SEO.SEO
          title={category.name}
          lang={category.node_locale}
          schemaOrgJSONLD={schemaOrgJSONLDList}
          link={category.slug}
          description={category.description.description}
        />

        <section
          css={this.contCss}
          className={"project-container init-animat-cont category"}
          data-current={category.slug}
          data-currentname={category.name}
          data-type={"category"}
          data-categoryslug={currentSlug}
          data-categoryname={category.name}
        >
          {getCategoryList(currentSlug)}
        </section>
      </>
    )
  }
}

export const pageQuery = graphql`
  query categoryQuery($id: String!) {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    contentfulCommonCategory(id: { eq: $id }) {
      id
      node_locale
      name
      slug
      description {
        description
      }
      content___works {
        slug
        name
        date
        title

        node_locale
        category {
          slug
        }
        tags {
          name
          slug
          group
        }
        thumbnail {
          title
          fluid(maxWidth: 800, quality: 70) {
            aspectRatio
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnailSp {
          title
          fluid(maxWidth: 400, quality: 70) {
            aspectRatio
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }

      content___journal {
        slug
        name
        date
        title
        node_locale
        category {
          slug
        }
        tags {
          name
          slug
          group
        }
        thumbnail {
          title
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnailSp {
          title
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      content___shop {
        slug
        title
        name
        date
        description {
          raw
        }
        shopifyId
        node_locale
        category {
          slug
        }
        tags {
          name
          slug
          group
        }
        thumbnail {
          title
          fluid(maxWidth: 800, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnails {
          fluid(maxWidth: 400, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        thumbnailSp {
          title
          fluid(maxWidth: 600, quality: 70) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allShopifyProductVariant {
      edges {
        node {
          id
          product {
            availableForSale
            vendor
          }
          presentmentPrices {
            edges {
              node {
                price {
                  amount
                  currencyCode
                }
              }
            }
          }
        }
      }
    }
  }
`
